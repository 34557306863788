@import '../../mixins.scss';

.border-primary {
    border: 1px solid $blue;
    box-shadow: 5px 6px 5px silver;
    display: flex;
    align-items: center;
    .feature {
        background-color: $blue !important;
        color: #fff !important;
        font-size: 20px;
        width: 100%;
        display: flex;
        justify-content: center;
    }

    .body-section {
        background-color: #eafdff6b;
        p{
            margin: 0;
            font-size: 20px;
            font-weight: bold;
            color: #333;
        }
        span , del{
            font-size: 12px;
            
        }
    }

    .price {
        color: $primary;
        font-size: 23px;
        font-weight: 800;
    }

    .list-none {
        list-style: none;
      
      li{
        margin: 1rem 0;
        border-bottom: 1px solid #666464;
        cursor: pointer;
      }
    }

    .links {
        text-decoration: none;
        color: $white;
        font-size: 20px;
        display: flex;
        justify-content: center;
    }
}