@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700;900&display=swap');

* {
   margin: 0;
   padding: 0;
   box-sizing: border-box;
   font-family: 'Roboto', sans-serif;
   font-size: 16px;
}

$primary :#ffb319;
$dark: #4a4a4a;
$pink : #ec467e;
$blue: #0099f5;
$white: #fff;
$blue-dark: #054d78;
$footer-text: #ddd;



@mixin xl {
   @media (max-width:1200px) {
      @content
   }
}

;

@mixin lg {
   @media (max-width:768px) {
      @content
   }
}

;

@mixin md {
   @media (max-width:576px) {
      @content
   }
}

;

@mixin sm {
   @media (max-width:414px) {
      @content
   }
}

;