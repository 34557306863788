@import '../../mixins.scss';


/*Side Form Design*/
.bgForm{
    background: url('../../../public/contact.png') ;
    background-size: contain;
    // background: #252540;
    width: 100%;
    height: 100%;
    border-radius: 10px 0px 0px 10px;
position: relative;
z-index: 1;
overflow: hidden;
background-repeat: no-repeat;
background-position: center;

.orange-circle{
    width: 272px;
    height: 272px;
    border-radius: 50%;
    background:  #0099f561;;
    position: absolute;
    z-index: 3;
    right: -25%;
    bottom: -37%;


}
.light-blue-circle{
    width: 200px;
    height: 200px;
    border-radius: 50%;
    background-color: #ffa50066 ;
    position: absolute;
    left: -14%;
    z-index: 4;
    top: -20%;
}
}
