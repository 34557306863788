@import './mixins.scss';

.container-fluid {
    max-width: 1200px;
}

.heading {
    font-size: 30px;
    font-weight: bold;
    line-height: 0.84;
    text-align: center;
    color: $primary;
    padding: 5%;
}

.btn-primary {
    background-color: $primary !important;
    border: $primary !important;
    box-shadow: $primary !important;
    &:hover{
        background-color: #dea32e !important;
    }
}

.list-none {
    list-style: none;
    margin-left: 0;
    padding-left: 0;
}

.text-primary {
    color: $primary !important;
}

a {
    text-decoration: none !important;
}

/*Home Page*/

/*hero section*/


.heroBg {
    width: 100%;
    margin-top: -70px;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgb(240, 251, 255);
    background: linear-gradient(128deg, rgba(240, 251, 255, 1) 0%, rgba(213, 244, 255, 1) 100%);

    max-height: 600px;
    overflow: hidden;

    @include lg {
        margin-top: 0;
        height: 778px;
        overflow: hidden;
    }

    .hero {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 600px;

        h1 {
            font-size: 25px;
        }
    }

    .right-section {
        // border: 1px solid black;
        position: relative;
        overflow: hidden;
        padding: 0;
        margin-bottom: -96px;

        .eclipse {
            position: absolute;
            content: "";
            clip-path: ellipse(50% 47% at 50% 48%);
            background-color: $primary;
            width: 100%;
            height: 100%;
            bottom: -252px;
        }

        .men-img {
            @include sm {
                margin-left: -9%;
            }
        }
    }
}

.password-input {
    position: relative;

    .ri-eye-line,
    .ri-eye-off-line {
        position: absolute;
        bottom: 10%;
        right: 5%;
        cursor: pointer;
    }
}

.goToLogIn {
    transform: translate(19%, 0);
    padding-top: 5%;

    span {
        color: #6913b9;
        cursor: pointer;
    }
}

.goToSignUp {
    padding-top: 5%;
    font-size: 13px;

    span {
        font-size: 13px;
        color: #6913b9;
        cursor: pointer;
    }
}

/*learninsection*/

.description {
    line-height: 2.1;
    text-align: justify;

    @include md {
        padding-top: 0% !important;

    }

}

.text-orange {
    color: $primary;

}

.text-pink {
    color: #ec1d3f
}

.text-blue {
    color: #6913b9;
}

/*review  section*/
.text-20 {
    font-size: 20px;
    line-height: 2;
}

.text-primary {
    color: $primary !important;
}

//review
.review-container {
    position: relative;

    .add-review {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 auto;
    }
}

.review {
    text-align: justify;
    word-spacing: -1px;

    @include md {
        text-align: center;
    }
}

.mySwiper {
    cursor: grab;
}

/*servicesection*/
.youtube-color {
    color: #CD201F;
}

.projector {
    color: $white;
}

.assign-video {
    color: $pink;
}

.service-icon {
    color: $blue;
}



/*Free Pdf*/
.select-university {
    margin-top: 3rem;
    text-align: center;

    @include md {
        text-align: left;
    }
}

.subject-card {
    cursor: pointer;
    margin-top: 1rem;

    .card-text {
        text-overflow: ellipsis;
        -webkit-line-clamp: 4;
        display: -webkit-box;
        overflow: hidden;
        -webkit-box-orient: vertical;
    }
}

/*Product View*/
.product-view-page {
    margin-top: 5%;
}

.product-description {
    h2 {
        font-size: 30px;
    }

    span {
        color: rgb(127, 127, 127);
    }

    ul {
        list-style: none;
        padding-left: 0;
        color: $primary;
        margin-bottom: 10px;

        li {
            display: inline-block;

            i {
                font-size: 20px;
            }
        }
    }

    .btn-success {
        margin-left: 10px;
    }
}

.view-product-img {
    max-width: 530px;
    max-height: 600px;
    overflow: hidden;
}

//payment-page
.payment-form {

    background-color: #87ceeb36;
    padding: 12px 5%;
    margin-top: 3rem;


}

.barcode-img {
    // margin: 2.5rem auto;
    width: 50%;
}

.works-list {
    list-style: numeric;
    padding-left: 1.4%;
}

//404Page
.error-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;

    h3 {
        font-size: 120px;
    }

    p {
        font-size: 60px;
    }
}

//profilepage
.myProfileContainer {
    padding-top: 5%;

    .myProfile {

        h5 {
            font-size: 25px;
            color: $primary;
        }

        .menu {
            li {
                padding: 3% 0;
                border-bottom: 1px solid $blue;
                cursor: pointer;

                .menuIcon {
                    color: $blue;
                }
            }
        }
    }

    .tableSide {
        h5 {
            color: $blue;
        }

        margin-top: 10%;
    }
}


.unordered-list{
    padding-left: 0;
}